export const columns = [
  {
    label: "Received",
    field: "timestamp",
    width: 150,
  },
  {
    label: "Journal",
    field: "journal_name",
    width: 120,
  },
  {
    label: "Manuscript ID",
    field: "manuscript_id",
    width: 200,
  },
  {
    label: "Manuscript title",
    field: "title",
  },
  { label: "Tortured phrases", field: "match_status" },
];
