import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import SpinnerOverlay from "../SpinnerOverlay/SpinnerOverlay";

const ModalWrapper = ({ isLoading, isOpen, onClose, title, children }) => {
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <MDBModal staticBackdrop show={isOpen} tabIndex="-1">
      <SpinnerOverlay active={isLoading}>
        <MDBModalDialog className="modal-lg">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{title}</MDBModalTitle>
              <MDBBtn
                type="button"
                className="btn-close"
                color="none"
                onClick={handleCloseModal}
              />
            </MDBModalHeader>
            <MDBModalBody className="px-4">{children}</MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </SpinnerOverlay>
    </MDBModal>
  );
};

export { ModalWrapper };
