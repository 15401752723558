import { MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";

export const renderListItems = (items, renderItem) => {
  return (
    <MDBListGroup flush className="mt-4">
      {items?.map((item, index) => (
        <MDBListGroupItem key={index} className="d-flex align-items-start">
          {renderItem(item)}
        </MDBListGroupItem>
      ))}
    </MDBListGroup>
  );
};

export const renderWordFilePropertyItem = (item) => (
  <div className="p-2">{item?.value}</div>
);
