import { MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";

export const renderListItems = (items, renderItem) => {
  return items?.map((item, index) => (
    <MDBListGroup flush="true" key={index} className="mt-4">
      {renderItem(item)}
    </MDBListGroup>
  ));
};

export const renderDoiItem = (item) => (
  <MDBListGroupItem>
    <div>
      <strong>DOI:</strong> {item?.DOI}
    </div>
    <div>
      <strong>Reference:</strong> {item?.title}
    </div>
  </MDBListGroupItem>
);
