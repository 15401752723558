import { useState } from "react";
import { columns } from "../columns";
import DataTable from "../../../components/table/DataTable";
import useDuplicateSubmissions from "../hooks/useDuplicateSubmissions";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import Pagination from "../../../components/Pagination/Pagination";
import NoContentMessage from "../../../components/NoContentMessage/NoContentMessage";
import { DuplicateSubmissionReportModal } from "../../../components";
import { ColorizeStatusText } from "../components/ColorizeStatusText";
import { ApplyAction } from "../components/ApplyAction";
import { convertISOToReadableDate } from "../../../helpers";

const ManuscriptsInboxTab = ({ type, isActiveTab }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const {
    data,
    fetchData,
    setData,
    setTriggerOrMatchStatus,
    isLoading,
    error,
    pageCount,
  } = useDuplicateSubmissions({ currentPage, type, isActiveTab });

  const updateStatusWithRollbackAndReload = async (index, status) => {
    const newData = [...data];
    const previousElement = { ...newData[index] };
    newData.splice(index, 1);
    setData(newData);

    const { match_id, persistent_id } = previousElement;
    try {
      await setTriggerOrMatchStatus(match_id, persistent_id, status);
      await fetchData();
    } catch (error) {
      newData.splice(index, 0, previousElement);
      setData(newData);
    }
  };

  const onActionSelect = (index, status) => {
    updateStatusWithRollbackAndReload(index, status);
  };

  const handleRowClick = (index) => {
    if (index === null) {
      return;
    }
    setSelectedRowIndex(index);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const dataWithActions = data?.map((element, index) => ({
    ...element,
    timestamp: convertISOToReadableDate(element.timestamp, "DD MMM YYYY"),
    match_status: <ColorizeStatusText statusText={element.match_status} />,
    action: (
      <div className="d-flex">
        <ApplyAction
          index={index}
          status={data[index].submission_status}
          selectAction={onActionSelect}
        />
      </div>
    ),
    clickEvent: () => handleRowClick(index),
  }));

  return (
    <SpinnerOverlay active={isLoading} centerOnScreen={true}>
      <DuplicateSubmissionReportModal
        contentData={data[selectedRowIndex]}
        onClose={() => setSelectedRowIndex(null)}
      />
      {error && <ErrorMessage />}
      {!error && !isLoading && data?.length === 0 && <NoContentMessage />}
      {!error && data?.length > 0 && (
        <>
          <DataTable columns={columns} rows={dataWithActions} />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>
      )}
    </SpinnerOverlay>
  );
};

export default ManuscriptsInboxTab;
