import { useEffect, useState } from "react";
import Pagination from "../../components/Pagination/Pagination";
import NoContentMessage from "../../components/NoContentMessage/NoContentMessage";
import SpinnerOverlay from "../../components/SpinnerOverlay/SpinnerOverlay";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import DataTable from "../../components/table/DataTable";
import { columns } from "./columns";
import { convertISOToReadableDate, getClearSkiesList } from "../../helpers";
import { MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import { ITEMS_PER_PAGE } from "../../constants";
import { CountCard } from "../../components";
import { ClearskiesReviewModal } from "./components/ClearskiesReviewModal";

const Clearskies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalHits, setTotalHits] = useState(0);
  const [error, setError] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedDataRowIndex, setSelectedDataRowIndex] = useState(null);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleRowClick = (index) => {
    setSelectedDataRowIndex(index);
  };

  const handleCloseModal = () => setSelectedDataRowIndex(null);

  const dataWithActions = data?.map((element, index) => ({
    ...element,
    timestamp: convertISOToReadableDate(element.timestamp, "DD MMM YYYY"),
    status: (
      <MDBIcon
        fas
        icon="exclamation-circle"
        size="xl"
        style={{ color: element?.screening_outcomes?.[0].items[0]?.value }}
      />
    ),
    clickEvent: () => handleRowClick(index),
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data: { manuscripts = [], total = 0 } = {} } =
          await getClearSkiesList();
        setData(manuscripts);
        setTotalHits(total);
        setPageCount(Math.ceil(total / ITEMS_PER_PAGE));
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <MDBContainer className="my-5">
      <SpinnerOverlay active={isLoading}>
        {selectedDataRowIndex !== null && (
          <ClearskiesReviewModal
            contentData={data[selectedDataRowIndex]}
            onClose={handleCloseModal}
          />
        )}
        {error && <ErrorMessage />}
        {!error && !isLoading && data?.length === 0 && <NoContentMessage />}
        {!error && data?.length > 0 && (
          <>
            <div className="d-flex justify-content-center m-5">
              <CountCard
                count={totalHits}
                title={"PAPERMILL SIMILARITY"}
                subtitle={"Screened manuscripts with alarm"}
              />
            </div>
            <DataTable columns={columns} rows={dataWithActions} />
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              currentPage={currentPage}
            />
          </>
        )}
      </SpinnerOverlay>
    </MDBContainer>
  );
};

export default Clearskies;
