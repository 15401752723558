import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon
} from 'mdb-react-ui-kit';
import styled from 'styled-components';

const DropdownToggle = styled(MDBDropdownToggle)`
    padding-top: 0.2rem;
    :after { display: none };
`;

const dropdownOptions = [
  {
    label: 'Confirm duplicate to other publisher(s)',
    value: 'duplicate confirmed'
  },
  {
    label: 'Paper mill suspect & report to other publisher(s)',
    value: 'suspect paper mill'
  },
  {
    label: 'False positive, hide from my list & report to other publisher(s) and STM',
    value: 'false positive'
  },
  {
    label: 'Hide from my list',
    value: 'hidden'
  }
];

const ApplyAction = ({ index, status, selectAction, undoAction }) => {

  const handleDropdownItemClick = (e) => {
    e.preventDefault();
    const value = e.currentTarget.getAttribute('data');
    selectAction(index, value);
  };

  const handleUndoIconClick = (e) => {
    e.preventDefault();
    undoAction(index);
  };

  return (
    <div
      onClick={e => e.stopPropagation()}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
    >
      {!status && (
        <MDBDropdown>
          <DropdownToggle
            color='secondary'
            className='btn-floating'
          >
            <MDBIcon icon='angle-down' />
          </DropdownToggle>
          <MDBDropdownMenu appendToBody responsive='end' >
            {dropdownOptions.map(e =>
              <MDBDropdownItem
                link key={e.value} data={e.value}
                onClick={handleDropdownItemClick}
              >
                {e.label}
              </MDBDropdownItem>)
            }
          </MDBDropdownMenu>
        </MDBDropdown>
      )}
      {status && (
        <div style={{ display: 'inline-flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
          <span>{status}</span>
          <MDBIcon
            fas icon='arrow-rotate-left' size='sm'
            color='primary'
            className='ms-2'
            onClick={handleUndoIconClick} />
        </div>
      )}
    </div>
  );
};

export { ApplyAction };
