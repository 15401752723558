import { MDBCard, MDBCardHeader, MDBCardTitle } from "mdb-react-ui-kit";
import { renderIndicationDisplay } from "./TextAnalysisDataReviewUtils";
import { InfoTooltip } from "../../../components";

const TextAnalysisDataReview = ({ data, withSubHeader = false }) => {
  const torturedPhrases =
    data?.screening_outcomes.find((x) => x.type === "torturedPhrases")?.items ??
    [];

  return (
    <>
      {!!torturedPhrases.length && (
        <MDBCard>
          {withSubHeader && (
            <MDBCardHeader>
              <MDBCardTitle>
                Tortured phrases{" "}
                <InfoTooltip
                  tooltipText={
                    <>
                      The text of the manuscript is checked against thousands of
                      phrases that were identified by publishers and Dr.
                      Guillame Cabanac et al. as likely being AI-generated or
                      rewritten. The database is refreshed regularly.
                    </>
                  }
                />
              </MDBCardTitle>
            </MDBCardHeader>
          )}
          {torturedPhrases.map((item, index) =>
            renderIndicationDisplay(item, index)
          )}
        </MDBCard>
      )}
    </>
  );
};

export default TextAnalysisDataReview;
