import { useMemo, useState, useEffect } from "react";
import DataTable from "../../../components/table/DataTable";
import { columns } from "../columns";
import Pagination from "../../../components/Pagination/Pagination";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import SearchInput from "../../../components/SearchInput/SearchInput";
import { TooltipButton } from "../components/TooltipButton";
import useScreenedManuscripts from "../hooks/useScreenedManuscripts";
import NoContentMessage from "../../../components/NoContentMessage/NoContentMessage";
import { DuplicateSubmissionReportModal } from "../../../components";
import { AmbientScreeningReviewModal } from "../components/AmbientScreeningReviewModal";
import {
  convertISOToReadableDate,
  getDuplicateSubmissionById,
} from "../../../helpers";

export const AmbientScreenedManuscriptsTab = ({ isActiveTab }) => {
  const [selectedDupSubItemIndex, setSelectedDupSubItemIndex] = useState(null);
  const [
    selectedAmbientScreeningItemIndex,
    setSelectedAmbientScreeningItemIndex,
  ] = useState(null);
  const [modalContentData, setModalContentData] = useState(null);

  const {
    data,
    isLoading,
    setIsLoading,
    error,
    pageCount,
    currentPage,
    setCurrentPage,
    searchTerm,
    setSearchTerm,
  } = useScreenedManuscripts({ tab: "all_manuscripts", isActiveTab });

  const handleDupSubIconClick = (index) => {
    setSelectedDupSubItemIndex(index);
  };

  const handleAmbientScreeningIconClick = (index) => {
    setSelectedAmbientScreeningItemIndex(index);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const allManuscriptsWithActions = useMemo(() => {
    return data?.map((manuscript, i) => ({
      ...manuscript,
      timestamp: convertISOToReadableDate(manuscript.timestamp, "DD MMM YYYY"),
      signals: (
        <TooltipButton
          key={i}
          manuscript={manuscript}
          handleDupSubIconClick={() => handleDupSubIconClick(i)}
          handleAmbientScreeningIconClick={() =>
            handleAmbientScreeningIconClick(i)
          }
        />
      ),
    }));
  }, [data]);

  const handleSearchFetch = async (value) => {
    const trimmedValue = value.trim();
    if (!searchTerm && !trimmedValue) return;
    setSearchTerm(trimmedValue);
  };

  const fetchDupSubDataIfNeeded = async (index) => {
    const item = data[index];
    if (!item || item.report_type === "duplicateSubmissions") {
      setModalContentData(item);
      return;
    }
    if (item.persistent_id) {
      setIsLoading(true);
      try {
        const { data: fetchedData } = await getDuplicateSubmissionById(
          item.persistent_id
        );
        setModalContentData(fetchedData);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (selectedDupSubItemIndex !== null) {
      fetchDupSubDataIfNeeded(selectedDupSubItemIndex);
    } else {
      setModalContentData(null);
    }
  }, [selectedDupSubItemIndex]);

  useEffect(() => {
    if (selectedAmbientScreeningItemIndex !== null) {
      setModalContentData(data[selectedAmbientScreeningItemIndex]);
    } else {
      setModalContentData(null);
    }
  }, [selectedAmbientScreeningItemIndex]);

  return (
    <SpinnerOverlay active={isLoading} centerOnScreen={true}>
      {selectedDupSubItemIndex !== null && modalContentData && (
        <DuplicateSubmissionReportModal
          contentData={data[selectedDupSubItemIndex]}
          onClose={() => setSelectedDupSubItemIndex(null)}
        />
      )}
      {selectedAmbientScreeningItemIndex !== null && modalContentData && (
        <AmbientScreeningReviewModal
          contentData={modalContentData}
          onClose={() => setSelectedAmbientScreeningItemIndex(null)}
        />
      )}
      <MDBRow className="mb-2 justify-content-end">
        <MDBCol sm={8} md={6} lg={4}>
          <SearchInput
            placeholder="Search by manuscript ID or title"
            setValue={setSearchTerm}
            onSubmit={handleSearchFetch}
          />
        </MDBCol>
      </MDBRow>
      {error && <ErrorMessage />}
      {!error && !isLoading && data?.length === 0 && <NoContentMessage />}
      {!error && data?.length > 0 && (
        <>
          <DataTable columns={columns} rows={allManuscriptsWithActions} />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>
      )}
    </SpinnerOverlay>
  );
};
