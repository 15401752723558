import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { renderListItems, renderDoiItem } from "./ReferenceAnalysisReviewUtils";
import { InfoTooltip } from "../../../components";

const ReferenceAnalysisDataReview = ({ data }) => {
  const retractionWatch =
    data?.screening_outcomes?.find((x) => x.type === "retractionWatch")
      ?.items ?? [];
  const doiResolutions =
    data?.screening_outcomes?.find((x) => x.type === "doiResolutions")?.items ??
    [];

  return (
    <>
      {retractionWatch.length > 0 && (
        <MDBCard className="my-2">
          <MDBCardBody>
            <MDBCardHeader>
              <MDBCardTitle>
                Retraction Watch{" "}
                <InfoTooltip
                  tooltipText={
                    <>
                      This signal checks if references are present in the
                      Retraction Watch database
                      <br />
                      &#x25cf; The first number displays the number of
                      references found in Retraction Watch database.
                      <br />
                      &#x25cf; The number in brackets is the total number of
                      references found in the manuscript.
                    </>
                  }
                />
              </MDBCardTitle>
            </MDBCardHeader>
            {renderListItems(retractionWatch, renderDoiItem)}
          </MDBCardBody>
        </MDBCard>
      )}

      {doiResolutions.length > 0 && (
        <MDBCard className="my-2">
          <MDBCardBody>
            <MDBCardHeader>
              <MDBCardTitle>
                DOI Analysis
                <InfoTooltip
                  tooltipText={
                    <>
                      This signal analyses references in the manuscript.
                      <br />
                      &#x25cf; The first number signifies the number of DOIs
                      listed in the reference list that do not exist (via check
                      with DOI.org).
                      <br />
                      &#x25cf; The second number signifies the total number of
                      DOIs that were listed in the reference list or could be
                      retrieved using our software.
                      <br />
                      &#x25cf; The number in brackets is the total number of
                      references that were found in the manuscript.
                    </>
                  }
                />
              </MDBCardTitle>
            </MDBCardHeader>
            {renderListItems(doiResolutions, renderDoiItem)}
          </MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default ReferenceAnalysisDataReview;
