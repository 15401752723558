import { MDBCol, MDBRow, MDBTypography, MDBContainer } from "mdb-react-ui-kit";
import { convertISOToReadableDate } from "../../helpers";

export const ReviewModalGeneralInfoCard = ({ data }) => {
  const rowStyle = (index) => ({
    backgroundColor: index % 2 === 0 ? "#f7f7f7" : "#ffffff",
    padding: "0.75rem 0.5rem",
    borderRadius: "5px",
  });

  return (
    <div className="bg-light rounded shadow-sm">
      <MDBContainer>
        {data?.timestamp && (
          <MDBRow className="mb-2" style={rowStyle(0)}>
            <MDBCol size="3">
              <MDBTypography tag="h6" className="mb-0 text-secondary">
                Date:
              </MDBTypography>
            </MDBCol>
            <MDBCol>
              <MDBTypography tag="h6" className="mb-0">
                {convertISOToReadableDate(data?.timestamp, "DD MMM YYYY HH:mm")}
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        )}

        {data?.journal_name && (
          <MDBRow className="mb-2" style={rowStyle(1)}>
            <MDBCol size="3">
              <MDBTypography tag="h6" className="mb-0 text-secondary">
                Journal:
              </MDBTypography>
            </MDBCol>
            <MDBCol>
              <MDBTypography tag="h6" className="mb-0">
                {data?.journal_name}
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        )}

        {data?.id && (
          <MDBRow className="mb-2" style={rowStyle(2)}>
            <MDBCol size="3">
              <MDBTypography tag="h6" className="mb-0 text-secondary">
                Manuscript ID:
              </MDBTypography>
            </MDBCol>
            <MDBCol>
              <MDBTypography tag="h6" className="mb-0">
                {data?.id}
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        )}

        {data?.title && (
          <MDBRow className="mb-2" style={rowStyle(3)}>
            <MDBCol size="3">
              <MDBTypography tag="h6" className="mb-0 text-secondary">
                Title:
              </MDBTypography>
            </MDBCol>
            <MDBCol>
              <MDBTypography tag="h6" className="mb-0">
                {data?.title}
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        )}
      </MDBContainer>
    </div>
  );
};
