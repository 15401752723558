import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import {
  renderListItems,
  renderWordFilePropertyItem,
} from "./MetadataAnalysisDataUtils";

const MetadataAnalysisDataReview = ({ data, withSubHeader = false }) => {
  const wordFileProperties =
    data?.screening_outcomes?.find((x) => x.type === "wordFileProperties")
      ?.items ?? [];

  return (
    <>
      {wordFileProperties.length > 0 && (
        <MDBCard className="my-2">
          {withSubHeader && (
            <MDBCardHeader>
              <MDBCardTitle>Word file metadata</MDBCardTitle>
            </MDBCardHeader>
          )}
          <MDBCardBody>
            {renderListItems(wordFileProperties, renderWordFilePropertyItem)}
          </MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default MetadataAnalysisDataReview;
