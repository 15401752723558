import {
  ModalWrapper,
  ReviewModalGeneralInfoCard,
} from "../../../components/ModalWrapper";
import WatchlistDataReview from "../../watchlist/components/WatchlistDataReview";
import ClearskiesDataReview from "../../clearskies/components/ClearskiesDataReview";
import ReferenceAnalysisDataReview from "../../reference-analysis/components/ReferenceAnalysisDataReview";
import MetadataAnalysisDataReview from "../../MetadataAnalysis/components/MetadataAnalysisDataReview";
import PubpeerDataReview from "../../pubpeer/components/PubpeerDataReview";
import TextAnalysisDataReview from "../../text-analysis/components/TextAnalysisDataReview";

export const AmbientScreeningReviewModal = ({ onClose, contentData }) => {
  const isOpen = !!contentData;

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <ModalWrapper
      title={"Ambient screening outcomes"}
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <ReviewModalGeneralInfoCard data={contentData} />
      <WatchlistDataReview data={contentData} />
      <ClearskiesDataReview data={contentData} withSubHeader />
      <ReferenceAnalysisDataReview data={contentData} />
      <MetadataAnalysisDataReview data={contentData} withSubHeader />
      <PubpeerDataReview data={contentData} withSubHeader />
      <TextAnalysisDataReview data={contentData} withSubHeader />
    </ModalWrapper>
  );
};
